<template>
  <div class="dashboard-side d-flex flex-between-column"  >
    <div>
      <div class="" v-for="(section, i) in sections" :key="i">
        <!-- :to="{ name: section.name }" -->

        <router-link
          class="d-flex align-center justify-space-between cursor-pointer"
          :to="section.route"
          v-if="section.role[0] == userRole || section.role[1] == userRole"
        >
          <!-- {{section.name}}  -->
          <div
            class="d-flex align-center py-2 px-3 my-1"
            :class="section.active ? 'itemDashbord' : ''"
          >
            <img
              class="mx-2"
              width="25px"
              :src="
                require(`@/assets/images/profile/${section.icon}${
                  section.active ? '_activ' : '_inactiv'
                }.png`)
              "
              alt=""
            />
            <span
              class="font-22"
              :class="
                section.active ? 'activ_color_text' : 'inactiv_color_text'
              "
              >{{ section.title }}</span
            >
          </div>
          <!-- <transition name="fade" mode="out-in">
          <img
            class="profile-tab-arrow"
            v-if="section.active"
            :src="require('@/assets/images/profile/arrow-right.png')"
          />
        </transition> -->
        </router-link>
      </div>
    </div>

    <div>
      <!-- sign out -->
      <v-divider></v-divider>
      <div
        class="d-flex align-center cursor-pointer"
        @click="$emit('logoutHandler')"
      >
        <img
          class="mx-3"
          width="23"
          :src="require('@/assets/images/icons/logout.png')"
        />
        <span class="font-22 lightGreyText--text">{{ $t("signOut") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    userRole: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.dashboard-side {
  height: 510px;
}
.itemDashbord {
  background-color: #e5f1f4;
  width: 160px;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.activ_color_text {
  color: #001489;
}
.inactiv_color_text {
  color: #8e8e8e;
}
</style>

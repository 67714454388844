<template>
  <div class="profile">
    <div class="loading-logOut" v-if="loadingLogOut">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row>
      <v-col
        md="2"
        cols="12"
        class="px-0"
        v-if="!isMobile"
      >
        <div class="white dark-shadow pt-14 px-3 pb-10 profile-tabs"
        style="position: sticky !important; top: 80px;"
        >
          <side-menu
            :sections="sections"
            :userRole="userRole"
            @logoutHandler="logoutHandler"
          ></side-menu>
        </div>
      </v-col>
      <v-col md="10" cols="12" class="my-10">
        <div class="max-width">
          <div
            class="d-inline-flex align-center cursor-pointer mb-5"
            v-if="isMobile"
            @click="drawer = true"
          >
            <v-icon color="primary">mdi-menu</v-icon>
            <h3 class="mx-2 font-30 primary--text d-inline-block ml-2">
              {{ getTitele() }}
            </h3>
          </div>
        </div>
        <v-navigation-drawer
          v-model="drawer"
          fixed
          left
          temporary
          height="100vh"
          class="px-3"
        >
          <div class="mt-14 pt-10"></div>
          <side-menu
            :sections="sections"
            :userRole="userRole"
            @logoutHandler="logoutHandler"
          ></side-menu>
        </v-navigation-drawer>
        <transition name="fade" >
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideMenu from "./SideMenu.vue";
export default {
  components: { SideMenu },
  data: (vm) => ({
    drawer: false,
    loadingLogOut: false,
    sections: [
      {
        title: vm.$t("myProfile"),
        name: ["Profile-User"],
        active: true,
        icon: "home",
        route: "/profile/user",
        role: ["sender", "bringer"],
      },
      {
        title: vm.$t("myShipments"),
        name: [
          "Profile-Shipments",
          "myShipment",
          "myProduct",
          "Profile-Products",
        ],
        active: false,
        icon: "ship",
        route: "/profile/shipments",
        role: ["sender", "bringer"],
      },
      {
        title: vm.$t("myTrips"),
        name: ["Profile-Trips", "Profile-Trip-details"],
        active: false,
        icon: "plane",
        route: "/profile/trips",
        role: ["bringer"],
      },
      {
        title: vm.$t("myWallet"),
        name: ["Profile-Wallet"],
        active: false,
        icon: "wallet",
        route: "/profile/wallet",
        role: ["bringer"],
      },
      {
        title: vm.$t("notifications"),
        name: ["Profile-Notification"],
        active: false,
        icon: "notification",
        route: "/profile/notifications",
        role: ["bringer", "sender"],
      },
      {
        title: vm.$t("chats"),
        name: ["Profile-Chat"],
        active: false,
        icon: "chat",
        route: "/profile/chat",
        role: ["bringer", "sender"],
      },
    ],
  }),
  watch: {
    $route: {
      handler() {
        this.sections.filter((section) => {
          if (
            section.name[0] == this.$route.name ||
            section.name[1] == this.$route.name ||
            section.name[2] == this.$route.name ||
            section.name[3] == this.$route.name
          ) {
            section.active = true;
          } else {
            section.active = false;
          }
        });
      },
    },
  },
  computed: {
    userRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    async logoutHandler() {
      this.loadingLogOut = true;
      await this.$axios.post("/auth/logout").then(() => {
        this.loadingLogOut = false;
      });
      await this.$store.dispatch("removeUser");
      this.$router.replace("/");
    },
    getTitele() {
      if (this.$route.name == "Profile-User") {
        return "Profile User";
      } else if (this.$route.name == "myShipment") {
        return "My Shipment";
      } else if (this.$route.name == "myProduct") {
        return "My Product";
      } else if (this.$route.name == "Profile-Trips") {
        return "Profile Trips";
      } else if (this.$route.name == "Profile-Wallet") {
        return "Profile Wallet";
      } else if (this.$route.name == "Profile-Notification") {
        return "Notifications";
      } else if (this.$route.name == "Profile-Chat") {
        return "Chat";
      } else if (this.$route.name == "Profile-Shipments") {
        return "Shipments";
      } else if (this.$route.name == "Profile-Products") {
        return "Products";
      } else if (this.$route.name == "Profile-Products") {
        return "Products";
      } else if (this.$route.name == "Profile-Trip-details") {
        return "Trip Details";
      }
    },
  },
  created() {
    this.sections.filter((section) => {
      if (
        section.name[0] == this.$route.name ||
        section.name[1] == this.$route.name ||
        section.name[2] == this.$route.name ||
        section.name[3] == this.$route.name
      ) {
        section.active = true;
      } else {
        section.active = false;
      }
    });
  },
  beforeRouteEnter(_, _2, next) {
    if (
      localStorage.getItem("role") == "bringer" ||
      localStorage.getItem("role") == "sender"
    ) {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-logOut {
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  position: absolute;
  top: 0px;
  background: rgba(0, 0, 0, 0.185);
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-tabs {
  border-left: 1px solid #66656533 !important;
  border-right: 1px solid #66656533 !important;
}
</style>
